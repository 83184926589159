import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/404/">
      <SEO title="404" />

      <section className="page-404">
        <h1>Oops...</h1>
        <p>Something went wrong</p>
        <Link to="/" className="btn-custom btn-custom--arrow">
          Back to home page
        </Link>
      </section>
    </Layout>
  )
}

export default NotFoundPage
